import { FeatureEnum, PermissionEnum } from './common.constant';

export const PUBLIC_NAVIGATION = Object.freeze({
  login: '/auth/login',
  register: '/auth/register',
  forgotPassword: '/auth/forgotPassword',
  resetPassword: '/auth/resetPassword',
  somethingWentWrong: '/something-went-wrong',
});

export const PRIVATE_NAVIGATION = Object.freeze({
  dashboard: {
    view: {
      path: '/',
      feature: FeatureEnum.Dashboard,
      permission: PermissionEnum.View,
    },
  },
  users: {
    view: {
      path: '/manage_users',
      feature: FeatureEnum.Users,
      permission: PermissionEnum.View,
    },
  },
});
