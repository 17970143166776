import React, { Suspense, useEffect } from 'react';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from 'constants/navigation.constant';

import RequiresAuth from 'modules/Auth/components/RequiresAuth';

import SiteLoader from 'components/Loaders/SiteLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthToken } from 'reduxStore/slices/tokenSlice';
import { useAxiosPost } from 'hooks/useAxios';
import { setUserData } from 'reduxStore/slices/authSlice';
import { checkRolePermission } from 'utils';
import { useTranslation } from 'react-i18next';
import { useLanguages } from 'hooks/useLanguages';
import { useLanguage } from 'reduxStore/slices/languageSlice';
import { setDefaultTitle } from 'reduxStore/slices/documentTitleSlice';
import { languageConstant } from 'constants/common.constant';

const applySuspense = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <Suspense fallback={<SiteLoader />}>{route.element}</Suspense>,
  }));
};

const RequiresUnAuth = React.lazy(
  () => import('modules/Auth/components/RequiresUnAuth')
);

const NotFound = React.lazy(() => import('modules/Auth/pages/NotFound'));

const Dashboard = React.lazy(() => import('modules/DashBoard'));
const ManageUsers = React.lazy(() => import('modules/ManageUsers'));

export type RouteObjType = {
  path?: string;
  element: JSX.Element;
  children?: RouteObject[];
  errorElement?: JSX.Element;
  feature?: string;
  permission?: string;
};

export const applyRequiresAuth = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <RequiresAuth title={route.feature}>{route.element}</RequiresAuth>,
  }));
};

const Login = React.lazy(() => import('modules/Auth/pages/Login'));
const Register = React.lazy(() => import('modules/Auth/pages/Register'));
const ForgotPassword = React.lazy(() => import('modules/Auth/pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('modules/Auth/pages/ResetPassword'));

const Routes = () => {
  const authToken = useSelector(getAuthToken);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { getLanguages } = useLanguages();
  const storeLang = useSelector(useLanguage);
  const [postRequest] = useAxiosPost();

  useEffect(() => {
    document.title = 'ASL Shop';
    dispatch(setDefaultTitle());
  }, [window.location.href]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await postRequest('/auth/protected', {});
      dispatch(
        setUserData({
          user: {
            first_name: user.data?.first_name,
            last_name: user.data?.last_name,
          },
        })
      );
    };
    if (authToken) {
      fetchUser();
    }
  }, [authToken]);

  useEffect(() => {
    if (!window.location.href.includes(PUBLIC_NAVIGATION.somethingWentWrong)) {
      getLanguages();
    }
  }, []);

  const loadLanguage = async () => {
    try {
      if (storeLang?.allLanguages && storeLang?.defaultLanguage) {
        await Promise.allSettled(
          storeLang?.allLanguages?.map(
            async (element: { name: string; short_name: string }) => {
              const translationModule = await import(
                `localization/${element?.name}/translation.json`
              );
              i18n.addResourceBundle(
                element?.short_name,
                'translation',
                translationModule,
                true,
                true
              );
            }
          )
        );
      } else {
        const translationModule = await import(
          'localization/english/translation.json'
        );
        i18n.addResourceBundle(
          languageConstant.EN,
          'translation',
          translationModule,
          true,
          true
        );
      }
      await i18n?.changeLanguage(
        storeLang.language ?? storeLang?.defaultLanguage ?? languageConstant.EN
      );
    } catch (error) {
      // handle error
    }
  };

  useEffect(() => {
    if (!window.location.href.includes(PUBLIC_NAVIGATION.somethingWentWrong)) {
      loadLanguage();
    }
  }, [storeLang.language, i18n]);

  const AuthenticationRoutes = applySuspense([
    {
      path: PUBLIC_NAVIGATION.login,
      element: <Login />,
    },
    {
      path: PUBLIC_NAVIGATION.register,
      element: <Register />,
    },
    {
      path: PUBLIC_NAVIGATION.forgotPassword,
      element: <ForgotPassword />,
    },
    {
      path: PUBLIC_NAVIGATION.resetPassword,
      element: <ResetPassword />,
    },
  ]);

  const routesForNotAuthenticatedOnly: RouteObjType[] = applySuspense([
    {
      element: <RequiresUnAuth />,
      children: AuthenticationRoutes,
    },
  ]);

  const routesForAuthenticatedOnly: RouteObjType[] = applyRequiresAuth([
    {
      path: PRIVATE_NAVIGATION.dashboard.view.path,
      element: <Dashboard />,
      feature: PRIVATE_NAVIGATION.dashboard.view.feature,
      permission: PRIVATE_NAVIGATION.dashboard.view.permission,
    },
    {
      path: PRIVATE_NAVIGATION.users.view.path,
      element: <ManageUsers />,
      feature: PRIVATE_NAVIGATION.users.view.feature,
      permission: PRIVATE_NAVIGATION.users.view.permission,
    },
  ]);

  const routesForPublic: RouteObjType[] = [];

  const notFound: RouteObjType[] = [
    {
      path: '*',
      element: (
        <RequiresAuth>
          <NotFound />
        </RequiresAuth>
      ),
    },
  ];

  let finalRoutes = [
    ...routesForPublic,
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
    ...notFound,
  ];

  // Logic to ensures that only routes that the user has permission to access are included in the finalRoutes array.
  finalRoutes = finalRoutes.filter((route) => {
    if (route?.feature && route?.permission) {
      return checkRolePermission(route.feature, route.permission);
    }
    return true;
  });

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter(finalRoutes);

  return <RouterProvider router={router} />;
};

export default Routes;
