import Button from 'components/Button/Button';
import LogoutIcon from 'components/Icon/assets/LogoutIcon';
import ProfileIcon from 'components/Icon/assets/ProfileIcon';
import UserIcon2 from 'components/Icon/assets/UserIcon2';
import Modal from 'components/Modal';
import { languageConstant } from 'constants/common.constant';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'reduxStore/slices/authSlice';
import { setLanguage, useLanguage } from 'reduxStore/slices/languageSlice';
import store from 'reduxStore/store';
import { logout } from 'utils';

const Header = (props: { title?: string }) => {
  const { language, defaultLanguage } = useSelector(useLanguage);
  const { title } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useSelector(getAuth);
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);
  return (
    <header className="bg-gray-800 text-white p-4 flex justify-between relative">
      <Modal modalEl={modalRef} setIsOpen={setIsOpen} />
      <h1 className="text-2xl font-semibold">{title ?? ''}</h1>
      <div className="flex gap-3">
        <div className="flex rounded-full gap-1 bg-gray-600 items-center">
          <span
            className={`rounded-full px-4 cursor-pointer h-full w-full flex items-center ${
              (language === languageConstant.EN ||
                (!language && defaultLanguage === languageConstant.EN)) &&
              'bg-red-500'
            }`}
            onClick={() => dispatch(setLanguage({ language: languageConstant.EN }))}
          >
            EN
          </span>
          <span
            className={`rounded-full px-4 cursor-pointer h-full w-full flex items-center ${
              (language === languageConstant.ES ||
                (!language && defaultLanguage === languageConstant.ES)) &&
              'bg-red-500'
            }`}
            onClick={() => dispatch(setLanguage({ language: languageConstant.ES }))}
          >
            ES
          </span>
        </div>
        <button
          className="bg-white rounded-full p-1 text-2xl font-semibold"
          onClick={() => setIsOpen(true)}
        >
          <ProfileIcon className="text-dark m-2" />
        </button>
      </div>

      <div
        className={`flex flex-col absolute top-[65px] right-5 bg-white text-black rounded-md z-10 shadow-lg border gap-2  px-2 py-2 ${
          !isOpen && 'hidden'
        }`}
        ref={modalRef}
      >
        <span className="flex items-center gap-1">
          <UserIcon2 /> {user?.first_name ?? 'Profile'} {user?.last_name}
        </span>
        <Button
          onClickHandler={() => logout(store)}
          className="text-red-500 hover:bg-red-500 hover:text-white rounded-md flex text-lg items-center"
        >
          <LogoutIcon className="mr-2" /> Logout
        </Button>
      </div>
    </header>
  );
};

export default Header;
