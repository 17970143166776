export const languageConstant: { [key: string]: string } = {
  ES: 'es',
  EN: 'en',
  DEFAULT: 'en',
};

export const FeatureEnum = {
  Dashboard: 'Dashboard',
  Users: 'Users',
};

export const PermissionEnum = {
  Update: 'Update',
  Delete: 'Delete',
  Create: 'Create',
  View: 'View',
};

export const Roles = Object.freeze({
  Admin: 'Admin',
});
