import { ButtonProps } from './type';
import Loaders from '../Loaders';
import './style/index.css';

const getButtonClasses = (variant: string) => {
  switch (variant) {
    case 'primary':
      return 'button primary ';
    case 'danger':
      return 'button danger ';
    case 'dangerBorder':
      return 'button dangerBorder ';
    case 'red':
      return 'button red ';
    case 'orange':
      return 'button orange ';
    case 'gray':
      return 'button gray ';
    case 'grayLight':
      return 'button grayLight ';
    case 'secondary':
      return 'button secondary ';
    case 'primaryBordered':
      return 'button primaryBordered ';
    case 'secondaryBordered':
      return 'button secondaryBordered ';
    case 'whiteBordered':
      return 'button ';
    case 'purple':
      return 'button purple';
    case 'green':
      return 'button green';
    case 'selectedGreen':
      return 'button selectedGreen';
    case 'grayOutline':
      return 'button grayOutline';
    case 'greenLowOpacity':
      return 'button greenLowOpacity';
    default:
      return '';
  }
};

const Button = ({
  small,
  className,
  children,
  type = 'button',
  disabled,
  onClickHandler,
  variants,
  value,
  name,
  isLoading,
  customStyle,
  tooltipText,
}: ButtonProps) => {
  return (
    <>
      {onClickHandler || type !== 'button' ? (
        <button
          type={type}
          style={customStyle}
          disabled={disabled || isLoading}
          className={`${tooltipText && 'relative group'} ${
            variants && getButtonClasses(variants)
          }  ${small && ' !py-1.5 !px-2.5 !font-normal '} ${
            isLoading && 'flex justify-center items-center gap-0.5'
          }  ${className ?? ''}`}
          onClick={onClickHandler}
          name={name}
        >
          {value}
          {children}
          {isLoading && <Loaders className="ms-1" />}
        </button>
      ) : (
        <span
          style={customStyle}
          className={` ${tooltipText && 'relative group'} ${
            variants && getButtonClasses(variants)
          } ${small && '!py-1.5 !px-2.5 !font-normal '} ${className ?? ''}`}
        >
          {value}
          {children}
        </span>
      )}
    </>
  );
};

export default Button;
