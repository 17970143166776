import { IconInputProps } from '../types/icons';

const UserIcon2 = ({ className }: IconInputProps) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={` ${className ?? ''}`}
    >
      <path
        d="M22.5508 20.7038C21.0635 18.1325 18.7715 16.2888 16.0967 15.4148C17.4198 14.6271 18.4477 13.427 19.0227 11.9986C19.5977 10.5701 19.6878 8.9925 19.2794 7.50789C18.8709 6.02327 17.9864 4.71378 16.7617 3.78051C15.537 2.84724 14.0398 2.3418 12.5 2.3418C10.9603 2.3418 9.46305 2.84724 8.23834 3.78051C7.01363 4.71378 6.12913 6.02327 5.72068 7.50789C5.31223 8.9925 5.40241 10.5701 5.97737 11.9986C6.55233 13.427 7.58028 14.6271 8.90336 15.4148C6.22855 16.2878 3.93656 18.1316 2.44925 20.7038C2.39471 20.7928 2.35853 20.8917 2.34286 20.9949C2.32718 21.098 2.33232 21.2032 2.35797 21.3044C2.38362 21.4055 2.42927 21.5005 2.49222 21.5836C2.55516 21.6668 2.63413 21.7366 2.72447 21.7888C2.81481 21.841 2.91469 21.8745 3.01821 21.8875C3.12173 21.9004 3.22679 21.8925 3.3272 21.8642C3.42762 21.8359 3.52135 21.7878 3.60286 21.7227C3.68437 21.6575 3.75201 21.5768 3.80179 21.4851C5.64164 18.3054 8.89359 16.407 12.5 16.407C16.1065 16.407 19.3584 18.3054 21.1983 21.4851C21.2481 21.5768 21.3157 21.6575 21.3972 21.7227C21.4787 21.7878 21.5725 21.8359 21.6729 21.8642C21.7733 21.8925 21.8783 21.9004 21.9819 21.8875C22.0854 21.8745 22.1853 21.841 22.2756 21.7888C22.3659 21.7366 22.4449 21.6668 22.5079 21.5836C22.5708 21.5005 22.6164 21.4055 22.6421 21.3044C22.6678 21.2032 22.6729 21.098 22.6572 20.9949C22.6415 20.8917 22.6054 20.7928 22.5508 20.7038ZM7.03129 9.3757C7.03129 8.29409 7.35202 7.23676 7.95294 6.33743C8.55385 5.4381 9.40795 4.73715 10.4072 4.32324C11.4065 3.90932 12.5061 3.80102 13.5669 4.01203C14.6278 4.22305 15.6022 4.74389 16.367 5.50871C17.1318 6.27353 17.6527 7.24797 17.8637 8.3088C18.0747 9.36964 17.9664 10.4692 17.5525 11.4685C17.1386 12.4678 16.4376 13.3219 15.5383 13.9228C14.639 14.5237 13.5817 14.8445 12.5 14.8445C11.0501 14.8429 9.66001 14.2662 8.63476 13.241C7.60951 12.2157 7.03284 10.8256 7.03129 9.3757Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserIcon2;
