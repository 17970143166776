import Header from 'components/Layout/components/Header';
import Sidebar from 'components/Layout/components/Sidebar';

type Props = {
  children: React.ReactNode;
  title?: string;
};

const Layout = (props: Props) => {
  const { children, title } = props;

  // REVIEW: const sidebarIsCollapse = useSelector(getSidebarIsCollapse);
  // const sidebarRef = useRef<HTMLDivElement>(null);
  // const CurrentUser = useSelector(getCurrentUser);

  // sidebarIsCollapse ? "" : "sidebar__collapse"
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header title={title} />
        <main className="flex-1 p-4">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
