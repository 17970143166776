import DashboardStrokeSD from 'components/Icon/assets/dashboard';
import UserCrossIcon from 'components/Icon/assets/UserCrossIcon';
import { NavLink } from 'react-router-dom';

const sidebarElements = [
  {
    label: 'Dashboard',
    to: '/',
    icon: <DashboardStrokeSD />,
  },
  {
    label: 'Manage Users',
    to: '/manage_users',
    icon: <UserCrossIcon />,
  },
];

const Sidebar = () => {
  return (
    <aside className="w-64 bg-gray-700 text-white h-full">
      <nav className="p-4">
        <div>
          <div className="flex items-center">
            <span className="font-bold text-xl">ASL</span>
          </div>
          <hr className="my-4" />
        </div>
        <div className="flex flex-col gap-2">
          {sidebarElements.map((element) => (
            <NavLink
              key={element.label}
              to={element.to}
              className={({ isActive }) =>
                `p-2 flex items-center gap-2 ${
                  isActive ? 'bg-slate-800 rounded-md' : ''
                }`
              }
            >
              {element.icon}
              {element.label}
            </NavLink>
          ))}
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
